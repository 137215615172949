import {
  SET_ANCHOR_EL_APP,
  RESET_ANCHOR_EL_APP,
} from '../actions/app_navigation_action'

const INITIAL_STATE = {
  anchorElApp: null,
}

/**
 * reducer for app navigation
 * @param {object} state
 * @param {object} action
 * @returns {object}
*/
export default function(state = INITIAL_STATE, action) {

  switch(action.type) {

    case SET_ANCHOR_EL_APP: {
      return {
        ...state,
        anchorElApp: action.payload
      }
    }

    case RESET_ANCHOR_EL_APP: {
      return {
        ...state,
        anchorElApp: null,
      }
    }

    default:
      return state

  }
}
