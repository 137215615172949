import React, { Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/StarBorder'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import AppNavigation from '../containers/AppNavigationContainer'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
    },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  sectionJustify: {
    padding: '.5em .5em',
    margin: '10px auto',
    maxWidth: '640px',
    textAlign: 'left',
  },
  textSpacer: {
    marginTop: theme.spacing.unit * 3,
  },
  priceSpacer: {
    marginTop: theme.spacing.unit * 4,
  },
  appSpacer: theme.mixins.toolbar,

  toolbarTitle: {
    flex: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  footer: {
    marginTop: theme.spacing.unit * 8,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 6}px 0`,
  },
})

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '5 users included',
      '5 projects included',
      'Help center access',
      'Email support'
    ],
    buttonText: 'Register for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      '10 users included',
      '20 projects included',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      'unlimited projects included',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
]

/**
* Pricing component
* @param {object} props
*
* @returns {ReactElement}
*/
function Pricing2(props) {
  const { classes } = props;

  return (
    <Fragment>
      <CssBaseline />
      <AppNavigation className={classes.appBar} />
      <main className={classes.content}>
        <div className={classes.layout}>
          <div className={classes.sectionJustify} >
            <Typography variant="h3" gutterBottom>
              {'Pricing 2'}
            </Typography>
            <Divider />
            <div className={classes.textSpacer} />
            <Typography variant="subtitle1" component="p"
              gutterBottom
            >
              {'Quickly build an effective pricing table for your potential customers with this layout.'}
              {'It\'s built with default Material-UI components with little customization.'}
            </Typography>
          </div>
          <div className={classes.priceSpacer} />
          <Grid container spacing={40}
            alignItems="flex-end"
          >
            {tiers.map(tier => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title}
                xs={12} sm={tier.title === 'Enterprise' ? 12 : 6}
                md={4}
              >
                <Card elevation={2} >
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h2" variant="h3"
                        color="textPrimary"
                      >
                        {`${tier.price}`}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        {'/mo'}
                      </Typography>
                    </div>
                    {tier.description.map(line => (
                      <Typography variant="subtitle1" align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button fullWidth variant={tier.buttonVariant}
                      color="primary"
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div className={classes.appSpacer} />
        </div>
      </main>
    </Fragment>
  )
}

Pricing2.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Pricing2)
