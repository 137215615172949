import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { withSnackbar } from 'notistack'

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import {
  signInUser,
  signInUserSuccess,
  signInUserFailure
  //resetUserFields
} from '../actions/users';
import renderTextField from '../common/components/renderTextField';
import renderPasswordField from '../common/components/renderPasswordField';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  buttonsDiv: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 2}px`,
  },
  button: {
    margin: theme.spacing.unit,
  },
  button2: {
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: 30,
    float: 'right'
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  formInput: {
    //flexBasis: 32,
  },
});

/**
 * Function Client side validation
 * @param {object} values
 *
 * @returns {Object}
*/
const validate = values => {
  const errors = {}
  var hasErrors = false;
  if (
    values.username &&
    !/^[A-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Z0-9!#$%&'*+/=?^_`{|}~-]+)+$/i.test(values.username)
  ) {
    errors.username = 'Invalid email address'
    hasErrors = true;
  }
  if (!values.username || values.username.trim() === '') {
    errors.username = 'Enter email address';
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }

  return hasErrors && errors;
}

//For any field errors upon submission (i.e. not instant check)
const validateAndSignInUser = (values, dispatch) => {

  return dispatch(signInUser(values))
    .then((result) => {
      //Store JWT Token to browser session storage
      //If you use localStorage instead of sessionStorage,
      //then this w/ persisted across tabs and new windows.
      //sessionStorage = persisted only in current tab
      sessionStorage.setItem('jwtToken', result.payload.data.token);
      //let other components know that everything is fine by updating the redux` state
      dispatch(signInUserSuccess(result.payload.data));
      //ps: this is same as dispatching RESET_USER_FIELDS
    })
    .catch((error) => {
      if (error.response && error.response.status !== 200) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        dispatch(signInUserFailure(error.response.data));
        throw new SubmissionError(error.response.data);
      }
    });
};

/**
 * Function Render SVG logo
 * @param {object} props
 *
 * @returns {Object}
*/
function SvgWithXlink (props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="86.000000pt"
      height="86.000000pt"
      viewBox="0 0 86.000000 86.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(18.000000,76.000000)
        scale(0.026000,-0.025000)"
        fill="#006DF0"
        stroke="none"
      >
        <path d="M70 960 l0 -960 93 0 92 1 290 360 290 360 3 64 3 65 42 0 c24 0 48
          5 55 12 7 7 12 38 12 70 0 65 -12 78 -74 78 l-36 0 0 313 0 314 -291 141 -290
          142 -95 0 -94 0 0 -960z m680 466 c0 -117 -3 -147 -12 -138 -8 8 -47 12 -109
          12 -122 0 -124 -2 -124 -125 0 -123 2 -125 124 -125 62 0 101 4 109 12 9 9 12
          -23 12 -145 l0 -158 -212 -262 c-116 -144 -213 -264 -215 -266 -1 -2 -3 347
          -3 777 l0 782 215 -107 215 -108 0 -149z"
        />
        <path d="M1301 1806 c-7 -9 -11 -42 -9 -88 l3 -73 74 -3 c41 -2 78 1 83 6 6 6
        10 40 10 78 1 54 -3 70 -18 81 -24 18 -128 17 -143 -1z"/>
        <path d="M1423 1540 c-17 -10 -37 -28 -42 -39 -7 -12 -11 -88 -11 -188 0 -238
        5 -243 240 -243 231 0 240 9 240 245 0 239 -6 245 -242 245 -134 0 -157 -3
        -185 -20z"/>
        <path d="M936 1489 c-25 -20 -26 -23 -26 -134 0 -152 3 -155 146 -155 87 0
        105 3 128 21 25 20 26 24 26 130 0 103 -1 111 -25 134 -22 23 -31 25 -123 25
        -85 0 -103 -3 -126 -21z"/>
        <path d="M1059 1077 c-32 -25 -39 -56 -39 -176 0 -112 2 -122 24 -148 23 -27
        28 -28 132 -31 198 -7 219 13 212 206 -3 110 -4 115 -31 138 -27 23 -35 24
        -155 24 -84 -1 -132 -5 -143 -13z"/>
        <path d="M1532 958 c-7 -7 -12 -40 -12 -78 0 -38 5 -71 12 -78 7 -7 40 -12 80
        -12 80 0 88 9 88 94 0 73 -14 86 -94 86 -35 0 -67 -5 -74 -12z"/>
        <path d="M904 670 c-29 -12 -44 -60 -44 -145 0 -88 14 -133 47 -146 13 -5 67
        -9 119 -9 87 0 97 2 119 25 23 23 25 31 25 130 0 99 -2 107 -25 130 -22 23
        -31 25 -122 24 -54 0 -108 -4 -119 -9z"/>
        <path d="M1356 664 c-10 -10 -16 -33 -16 -64 0 -63 17 -80 80 -80 63 0 80 17
        80 80 0 31 -6 54 -16 64 -21 21 -107 21 -128 0z"/>
      </g>
    </svg>
  );
}

/**
* SignIn component
* @param {object} message
* @param {object} variant
* @returns {ReactElement}
*/
/*eslint-disable react/no-multi-comp*/
/*eslint-disable require-jsdoc*/
class SignInForm extends Component {

  static contextTypes = {
    router: PropTypes.object
  };

  static propTypes = {
    resetMe: PropTypes.func.isRequired,
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    from: PropTypes.string.isRequired,
    reset: PropTypes.func.isRequired,
    showPassword: PropTypes.bool,
    setShowPassword: PropTypes.func.isRequired,
  };

  /**
   * @constructor
   */
  constructor() {
    super(...arguments);

    this.showAlert = this.showAlert.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
  }

  /**
   * Load data
   */
  componentWillMount() {
    const {
      reset,
      resetMe,
    } = this.props;

    // Important! If your component is navigating based on some global state
    // (from say componentWillReceiveProps)
    // always reset that global state back to null when you REMOUNT
    resetMe();
    // always reset that local state back to null
    reset('SignInForm');
  }

  /**
   * Data loaded
   * @param {object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const {
      from,
    } = this.props


    if (nextProps.user.status === 'authenticated' && nextProps.user.user && !nextProps.user.error) {
      this.context.router.history.push(from);
    }

    // error
    // Throw error if it was not already thrown
    // (check this.props.user.error to see if alert was already shown)
    // If u don't check this.props.user.error, u may throw error
    // multiple times due to redux-form's validation errors
    if (nextProps.user.status === 'signin'
      && !nextProps.user.user
      && nextProps.user.error
      && !this.props.user.error) {

      this.showAlert(nextProps.user.error.message, 'error');
    }
  }

  /**
   * Data reset
   */
  componentWillUnmount() {
    const {
      setShowPassword,
    } = this.props
    setShowPassword(false)
  }

  /**
   * Show or hide Password
  */
  handleClickShowPassword = () => {
    const {
      showPassword,
      setShowPassword,
    } = this.props
    setShowPassword(!showPassword)
  };

  /**
   * Show alert
   * @param {string} message
   * @param {string} variant
  */
  showAlert(message, variant) {
    const {
      classes,
    } = this.props;

    // variant could be success, error, warning or info
    this.props.enqueueSnackbar(message, {
      variant,
      action: (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          size="small"
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ),
    });
  }

  /**
   * Renders component
   * @returns {ReactElement}
   */
  render() {
    const {
      // asyncValidating,
      handleSubmit,
      submitting,
      from,
      isAuthenticated,
      classes,
      showPassword,
    } = this.props;

    return (
      <Fragment>
        {isAuthenticated && (
          <Redirect to={from || '/'} />
        )}
        <div className="container">
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper} >
              <SvgWithXlink />
              <Typography component="h1" variant="h5">
                {'Sign in'}
              </Typography>
              <form
                className={classes.form}
                onSubmit={handleSubmit(validateAndSignInUser)}
              >
                <Field
                  name="username"
                  component={renderTextField}
                  label="Email address"
                  htmlFor="email"
                  autoComplete="email"
                  type="input"
                  required={true}
                  className={classes.formInput}
                />
                <Field
                  name="password"
                  component={renderPasswordField}
                  label="Password"
                  htmlFor="adornment-password"
                  autoComplete="current-password"
                  required={true}
                  showPassword={showPassword}
                  onClick={this.handleClickShowPassword}
                  className={classes.formInput}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={submitting}
                >
                  {'Sign in'}
                </Button>
                <Button
                  color="primary"
                  fullWidth
                  className={classes.button2}
                  href="/forgotten"
                >
                  {'Forgot your password?'}
                </Button>
              </form>
            </Paper>
          </main>

        </div>
      </Fragment>
    )
  }
}

export default withSnackbar(withStyles(styles)(reduxForm({
  form: 'SignInForm', // a unique identifier for this form
  validate // <--- validation function given to redux-form
})(SignInForm)))
