import React, { Component } from 'react'

import DashboardContainer from '../containers/DashboardContainer'

/**
* Dashboard pages root component
*/
class Dashboard extends Component {

  /**
  * Renders Dashboard component
  *
  * @returns {ReactElement}
  */
  render() {

    return (
      <div>
        <DashboardContainer />
      </div>
    )

  }

}

export default Dashboard
