export const SET_ANCHOR_EL_APP = 'SET_ANCHOR_EL_APP'
export const RESET_ANCHOR_EL_APP = 'RESET_ANCHOR_EL_APP'

/**
 * Action creator to set anchor for navigation
 * @param {object} value
 *
 * @return {Object}
 */
export function setAnchorElApp(value) {
  return {
    type: SET_ANCHOR_EL_APP,
    payload: value
  }
}

/**
 * Action creator to reset anchor for navigation
 *
 * @return {Object}
 */
export function resetAnchorElApp() {
  return {
    type: RESET_ANCHOR_EL_APP
  }
}
