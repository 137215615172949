import { connect } from 'react-redux'

import {
  setOpenDrawer,
  setSignedOut,
  resetDashboard,
} from '../actions/dashboard_action'
import { logoutUser } from '../actions/users'

import Dashboard from '../components/Dashboard.js'

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  return {
    authenticatedUser: state.user.status === 'authenticated' ? state.user.user : null,
    user: state.user,
    isAuthenticated: state.user.isAuthenticated,
    open: state.dashboard.open,
    signedOut: state.dashboard.signedOut,
  }
}

/* eslint-disable no-unused-vars */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      sessionStorage.removeItem('jwtToken');
      dispatch(logoutUser());
    },
    setOpenDrawer: (value) => {
      dispatch(setOpenDrawer(value));
    },
    setSignedOut: (value) => {
      dispatch(setSignedOut(value));
    },
    resetDashboard: () => {
      dispatch(resetDashboard());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
