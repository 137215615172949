import React from 'react'
// import { PropTypes } from 'prop-types';
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

/**
 * Function Render Text field
 * @param {object} input
 * @param {string} label
 * @param {string} htmlFor
 * @param {boolean} autoComplete
 * @param {string} type
 * @param {boolean} required
 * @param {boolean} autoFocus
 * @param {object} meta
 *
 * @returns {Object}
*/
/* eslint-disable react/prop-types */
const renderTextField = ({
  input,
  label,
  htmlFor,
  autoComplete,
  type,
  required,
  autoFocus,
  meta: { touched, error },
  ...custom
}) => (
  /* eslint-enable react/prop-types */
  <FormControl
    margin="normal"
    required={required}
    fullWidth
    error={touched && !(error === '' || error == null)}
  >
    <InputLabel
      htmlFor={htmlFor}
    >
      {label}
    </InputLabel>
    <Input
      id={input.name}
      name={input.name}
      type={type}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      {...input}
      {...custom}
    />
    <FormHelperText id={`${input.name}-error-text`}>{touched && error}</FormHelperText>
  </FormControl>
)

export default renderTextField
