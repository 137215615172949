import axios from 'axios'


//update  email
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE'
export const RESET_UPDATE_EMAIL_STATE = 'RESET_UPDATE_EMAIL_STATE'

const ROOT_URL2 = window.location.href.indexOf('localhost') > 0 ? 'https://www.projectil.io/api' : '/api'

let ROOT_URL

if (window.location.href.indexOf('localhost') > 0) {
  ROOT_URL = ROOT_URL2
} else {
  ROOT_URL = window.location.origin + ROOT_URL2;
}

// only for development - localhost
//ROOT_URL = 'http://localhost:5000/api'

/**
 * Action creator update email
 *
 * @param {string} email
 * @param {object} tokenFromStorage
 *
 * @return {Object}
 */
export function updateEmail(email, tokenFromStorage) {
  const request = axios({
    method: 'post',
    data: email,
    url: `${ROOT_URL}/updateEmail`,
    headers: {'Authorization': `Bearer ${tokenFromStorage}`}
  });

  return {
    type: UPDATE_EMAIL,
    payload: request
  }
}

/**
 * Action creator update email success
 *
 * @return {Object}
 */
export function updateEmailSuccess() {
  return {
    type: UPDATE_EMAIL_SUCCESS
  }
}

/**
 * Action creator update email error
 * @param {object} error
 *
 * @return {Object}
 */
export function updateEmailFailure(error) {
  return {
    type: UPDATE_EMAIL_FAILURE,
    payload: error
  }
}

/**
 * Action creator reset state update email
 *
 * @return {Object}
 */
export function resetUpdateEmailState() {
  return {
    type: RESET_UPDATE_EMAIL_STATE
  }
}
