import React from 'react';

import TimelineItem from '../common/components/timeLineItem';
import '../assets/style/timeLine.css';

const timelineData = [
  {
    text: 'Wrote my first blog post ever on Medium',
    date: 'March 03 2019',
    category: {
      tag: 'medium',
      color: '#018f69'
    },
    link: {
      url:
      'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'Read more'
    }
  },
  {
    text: 'Create project on GitHub',
    date: 'March 07 2019',
    category: {
      tag: 'github',
      color: '#018f96'
    },
    link: {
      url:
      'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'View more'
    }
  },
  {
    text: 'Create second project on GitHub',
    date: 'March 14 2019',
    category: {
      tag: 'github',
      color: '#888f99'
    },
    link: {
      url:
      'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'View more'
    }
  },
  {
    text: 'Create third project on GitHub',
    date: 'June 14 2019',
    category: {
      tag: 'github',
      color: '#088f99'
    },
    link: {
      url:
      'https://medium.com/@popflorin1705/javascript-coding-challenge-1-6d9c712963d2',
      text: 'View more'
    }
  },
];
  
const Timeline = () =>
  timelineData.length > 0 && (
    <div className="timeline-container">
      {timelineData.map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  );

export default Timeline;

