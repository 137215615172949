import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'

import configureStore from './store/configureStore.js'

import './assets/style/index.css'
import './assets/style/material.css'
//import './assets/fonts/index.css'

import App from './App'
import registerServiceWorker from './registerServiceWorker'

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker()
