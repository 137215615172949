import React from 'react'
// import { PropTypes } from 'prop-types';

import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'

/**
 * Function Render Password field
 * @param {object} input
 * @param {string} label
 * @param {string} htmlFor
 * @param {boolean} autoComplete
 * @param {boolean} required
 * @param {boolean} autoFocus
 * @param {boolean} showPassword
 * @param {function} onClick
  * @param {object} meta
 *
 * @returns {Object}
*/
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
const renderPasswordField = ({
  input,
  label,
  htmlFor,
  autoComplete,
  required,
  autoFocus,
  showPassword,
  onClick,
  meta: { touched, error },
  ...custom
}) => (
  /* eslint-enable react/prop-types */
  <FormControl
    margin="normal"
    required={required}
    fullWidth
    error={touched && !(error === '' || error == null)}
  >
    <InputLabel
      htmlFor={htmlFor}
    >
      {label}
    </InputLabel>
    <Input
      id={input.name}
      name={input.name}
      type={showPassword ? 'text' : 'password'}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={onClick}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
      {...input}
      {...custom}
    />
    <FormHelperText id={`${input.name}-error-text`}>{touched && error}</FormHelperText>
  </FormControl>
)

export default renderPasswordField
