import React, { Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CssBaseline from '@material-ui/core/CssBaseline'

import AppNavigation from '../containers/AppNavigationContainer'
import TimeLine from '../containers/TimeLine';
import './Timeline.css';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
    },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  sectionJustify: {
    padding: '.5em .5em',
    margin: '10px auto',
    maxWidth: '640px',
    textAlign: 'left',
  },
  appSpacer: theme.mixins.toolbar,
})

/**
* Timeline page root component
*/
class Timeline extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  /**
  * Renders PublicPage component
  *
  * @returns {ReactElement}
  */
  render() {
    const {
      classes,
    } = this.props

    return (
      <Fragment>
        <CssBaseline />
        <AppNavigation className={classes.appBar} />
        <main className={classes.content}>
          <div className={classes.layout}>
            <div className={classes.sectionJustify} >
              <Typography variant="h3" gutterBottom>
                {'Timeline'}
              </Typography>
              <Divider />
              <TimeLine />
            </div>
            <div className={classes.appSpacer} />
          </div>
        </main>
        <Divider />
        <div className="main-container">
          <section id="timeline" className="timeline-outer">
            <div className="container" id="content">
              <div className="row">
                <div className="col s12 m12 l12">
                  <h1 className="blue-text lighten-1 header">{'Responsive Material Design Timeline'}</h1>
                  <ul className="timeline">
                    <li className="event" data-date="2015/Present">
                      <h3>{'Management and Entreprenurship (MSc)'}</h3>
                      <p>
                        {'This September 2015 I will begin an MSc in Management and Entrepreneurship at University of Sussex, to broaden my knowledge and gain skills necessary for my future in business and management.'}
                      </p>
                    </li>
                    <li className="event" data-date="2015/Present">
                      <h3>{'Claromentis'}</h3>
                      <p>
                        {'Claromentis is an intranet software provider company. I started working at the Brighton office as a Marketing Designer while I was still attending my final year at the University of Sussex. My primary responsibilities included creating corporate identity'}
                        {'for the company; I re-designed their website, and have created marketing materials such as brochures.'}
                      </p>
                      <p>
                        {'Since graduating from university, I have also undertaken responsibilities for designing a product for the company. The roles I have been given have provided the perfect opportunity to implement the skills I have gained throughout my higher'}
                        {'education, as well as experiencing the running of a successful business.'}
                      </p>
                    </li>
                    <li className="event" data-date="2012/2015">
                      <h3>{'Games & Multimedia Environments BSc (Hons)'}</h3>
                      <p>
                        {'Throughout my degree I have gained expansive knowledge of informatics areas including Human Computer Interaction, Multimedia Design and Development, Program Analysis and Design For my final year project, I created a 2D Puzzler Game for iOS'}
                        {'called \'Flat Ball\' and received a first. I therefore hope to release this game and further develop it to add new levels and improve the features.'}
                      </p>
                    </li>
                    <li className="event" data-date="2012/2015">
                      <h3>{'1108 Studios'}</h3>
                      <p>
                        {'This is a small startup that a friend and I created to gain more skills and apply those I had learned while completing my diploma. Since its inception, as a front - end web developer I have advised, designed and built web solutions for numerous'}
                        {'clients.'}
                      </p>
                    </li>
                    <li className="event" data-date="2010/2012">
                      <h3>{'IT Practitioners BTEC National Diploma'}</h3>
                      <p>
                        {'This is where my interest in building things for interactive media began. During my first computing course I studied a range of core topics including multimedia design, database design, computer games development, computer networks and object'}
                        {'oriented programming.'}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Timeline)
