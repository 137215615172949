import { combineReducers } from 'redux'
import PostsReducer from './reducer_posts'
import UserReducer from './reducer_user'
import ValidateUserFieldsReducer from './reducer_validateUserFields'
import ResendEmailReducer from './reducer_resendEmail'
import UpdateEmailReducer from './reducer_updateEmail'
import { reducer as formReducer } from 'redux-form'

import DashboardReducer from './reducer_dashboard'
import AppNavigationReducer from './reducer_app_navigation'

const rootReducer = combineReducers({
  user: UserReducer,
  validateFields: ValidateUserFieldsReducer,
  posts: PostsReducer, //<-- Posts
  form: formReducer, // <-- redux-form
  resendEmail: ResendEmailReducer,
  updateEmail: UpdateEmailReducer,
  dashboard: DashboardReducer,
  app_navigation: AppNavigationReducer,
})

export default rootReducer
