import {
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  ME_FROM_TOKEN,
  ME_FROM_TOKEN_SUCCESS,
  ME_FROM_TOKEN_FAILURE,
  RESET_TOKEN,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  RESET_USER,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  LOGOUT_USER,
  UPDATE_USER_EMAIL,
  SET_SHOW_PASSWORD
} from '../actions/users'

//user = userobj,
// status can be:
// 1. 'storage' ie. localstorage / sessionstorage)
// 2. 'signup' (signing up)
// 3. 'signin' (signing in)
// 4. 'validate'(validate fields)
// 5. 'validate_email' (validating email token)
// 5. 'authenticated'(after signin)
// 6. 'logout' (after logout)

const INITIAL_STATE = {
  user: null,
  status: null,
  error: null,
  loading: false,
  isAuthenticated: false,
  showPassword: false,
}

/**
 * reducer for user
 * @param {object} state
 * @param {object} action
 * @returns {object}
*/
export default function(state = INITIAL_STATE, action) {

  let error;

  switch(action.type) {

    case VALIDATE_EMAIL://check email verification token
      return {
        ...state,
        user: null,
        status: 'validate_email',
        error: null,
        loading: true,
        isAuthenticated: false
      }

    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        status: 'authenticated',
        error: null,
        loading: false,
        isAuthenticated: true
      } //<-- authenticated & email verified, isAuthenticated: true?

    case VALIDATE_EMAIL_FAILURE:
      error = action.payload.data || {message: action.payload.message}
      //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: 'validate_email',
        error,
        loading: false,
        isAuthenticated: false
      } //<-- authenticated

    case ME_FROM_TOKEN:// loading currentUser("me") from jwttoken in local/session storage storage,
      return {
        ...state,
        user: null,
        status: 'storage',
        error: null,
        loading: true,
        isAuthenticated: false
      }

    case ME_FROM_TOKEN_SUCCESS://return user, status = authenticated and make loading = false
      return {
        ...state,
        user: action.payload.data.user,
        status: 'authenticated',
        error: null,
        loading: false,
        isAuthenticated: true
      } //<-- authenticated

    case ME_FROM_TOKEN_FAILURE:// return error and make loading = false
      error = action.payload.data || {message: action.payload.message}
      //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: 'storage',
        error,
        loading: false,
        isAuthenticated: false
      }

    case RESET_TOKEN:// remove token from storage make loading = false
      return {
        ...state,
        user: null,
        status: 'storage',
        error: null,
        loading: false,
        isAuthenticated: false
      }

    case SIGNUP_USER:// sign up user, set loading = true and status = signup
      return {
        ...state,
        user: null,
        status: 'signup',
        error: null,
        loading: true,
        isAuthenticated: false
      }

    case SIGNUP_USER_SUCCESS://return user, status = authenticated and make loading = false
      return {
        ...state,
        user: action.payload.user,
        status: 'authenticated',
        error: null,
        loading: false,
        isAuthenticated: true
      } //<-- authenticated, isAuthenticated: true ?

    case SIGNUP_USER_FAILURE:// return error and make loading = false
      error = action.payload.data || {message: action.payload.message}
      //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: 'signup',
        error,
        loading: false,
        isAuthenticated: false
      }

    case SIGNIN_USER:// sign in user,  set loading = true and status = signin
      return {
        ...state,
        user: null,
        status: 'signin',
        error: null,
        loading: true,
        isAuthenticated: false
      }

    case SIGNIN_USER_SUCCESS:
    //return authenticated user,  make loading = false and status = authenticated
      return {
        ...state,
        user: action.payload.user,
        status: 'authenticated',
        error: null,
        loading: false,
        isAuthenticated: true
      } //<-- authenticated

    case SIGNIN_USER_FAILURE:// return error and make loading = false
      error = action.payload.data || {message: action.payload.message}
      //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: 'signin',
        error,
        loading: false,
        isAuthenticated: false
      }


    case UPDATE_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email:action.payload.email
        }
      }


    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        status: 'logout',
        error: null,
        loading: false,
        isAuthenticated: false
      }

    case RESET_USER:// reset authenticated user to initial state
      return {
        ...state,
        user: null,
        status: null,
        error: null,
        loading: false,
        isAuthenticated: false,
        showPassword: false
      }

    case SET_SHOW_PASSWORD: {
      return {
        ...state,
        showPassword: action.payload
      }
    }

    default:
      return state
  }
}
