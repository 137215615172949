import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import {
  setShowPassword,
} from '../actions/users'

import SignInForm from '../components/SignInForm.js';

/**
* SigValidate email pages root component
*/
class SignInFormContainer extends Component {

  static propTypes = {
    from: PropTypes.string,
    resetMe: PropTypes.func.isRequired,
    user: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    showPassword: PropTypes.bool,
    setShowPassword: PropTypes.func.isRequired,
  };

  /**
  * Renders SigIn component
  *
  * @returns {ReactElement}
  */
  render() {
    const {
      from,
      resetMe,
      user,
      isAuthenticated,
      showPassword,
      setShowPassword,
    } = this.props;

    return (
      <div>
        <SignInForm
          resetMe={resetMe}
          user={user}
          isAuthenticated={isAuthenticated}
          from={from}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </div>
    )
  }
}

/* eslint-disable no-unused-vars */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setShowPassword: (value) => {
      dispatch(setShowPassword(value));
    },
    resetMe: () =>{
      // sign up is not reused, so we dont need to resetUserFields
      // in our case, it will remove authenticated users
      // dispatch(resetUserFields());
    }
  }
}

/* eslint-disable no-unused-vars */
/**
 * Map state to props
 * @param {Object} state
 * @param {Object} ownProps
 * @return {Object}
 */
function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    isAuthenticated: state.user.isAuthenticated,
    showPassword: state.user.showPassword,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInFormContainer);
