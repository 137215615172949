export const SET_OPEN_DRAWER = 'SET_OPEN_DRAWER'
export const SET_SIGNED_OUT = 'SET_SIGNED_OUT'
export const RESET_DASHBOARD = 'RESET_DASHBOARD'

/**
 * Action creator to set state drawer
 * @param {object} value
 *
 * @return {Object}
 */
export function setOpenDrawer(value) {
  return {
    type: SET_OPEN_DRAWER,
    payload: value
  }
}

/**
 * Action creator to set signed
 * @param {object} value
 *
 * @return {Object}
 */
export function setSignedOut(value) {
  return {
    type: SET_SIGNED_OUT,
    payload: value
  }
}

/**
 * Action creator to reset anchor for navigation
 *
 * @return {Object}
 */
export function resetDashboard() {
  return {
    type: RESET_DASHBOARD
  }
}
