const styles = {
  /*
  navigation: {
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: grey600,
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: typography.fontWeightLight,
    marginBottom: 20
  },
  */
  paper: {
    padding: 30
  },
  clear: {
    clear: 'both'
  },
  publicPageContainer: {
    minWidth: 272,
    maxWidth: '90%',
    height: 'auto',
    position: 'absolute',
    top: '10%',
    left: 32,
    right: 16,
    bottom: '10%',
    margin: 'auto'
  },
  justify: {
    textAlign: 'left',
  },
  section: {
    padding: '.5em .5em',
    margin: '0 auto',
    maxWwidth: '640px',
  }
}

export default styles
