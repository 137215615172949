import axios from 'axios'

//Validate user fields like name and password
export const VALIDATE_USER_FIELDS = 'VALIDATE_USER_FIELDS'
export const VALIDATE_USER_FIELDS_SUCCESS = 'VALIDATE_USER_FIELDS_SUCCESS'
export const VALIDATE_USER_FIELDS_FAILURE = 'VALIDATE_USER_FIELDS_FAILURE'
export const RESET_VALIDATE_USER_FIELDS = 'RESET_VALIDATE_USER_FIELDS'


// Note when running locally,
// the node server running on localhost:3000 and the dev-server is running on 8080
// When running on Heroku, we run both on the same port and so just /api is enough.
/*eslint-disable max-len*/
const ROOT_URL2 = window.location.href.indexOf('localhost') > 0 ? 'https://www.projectil.io/api' : '/api'
/*eslint-enable max-len*/

let ROOT_URL

if (window.location.href.indexOf('localhost') > 0) {
  ROOT_URL = ROOT_URL2
} else {
  ROOT_URL = window.location.origin + ROOT_URL2
}

// only for development - localhost
//ROOT_URL = 'http://localhost:5000/api'

/**
 * Action creator to validate user fields
 * @param {object} values
 *
 * @return {Object}
 */
export function validateUserFields(values) {
  //note: we cant have /users/validateFields because it'll match /users/:id path!
  const request = axios.post(`${ROOT_URL}/users/validate/fields`, values);

  return {
    type: VALIDATE_USER_FIELDS,
    payload: request
  }
}

/**
 * Action creator to validate user fields - success
 *
 * @return {Object}
 */
export function validateUserFieldsSuccess() {
  return {
    type: VALIDATE_USER_FIELDS_SUCCESS
  }
}

/**
 * Action creator to validate user fields - error
 * @param {object} error
 *
 * @return {Object}
 */
export function validateUserFieldsFailure(error) {
  return {
    type: VALIDATE_USER_FIELDS_FAILURE,
    payload: error
  }
}

/**
 * Action creator to reset validate user fields
 *
 * @return {Object}
 */
export function resetValidateUserFields() {
  return {
    type: RESET_VALIDATE_USER_FIELDS
  }
}
