import axios from 'axios'
//Post list
export const FETCH_POSTS = 'FETCH_POSTS'
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS'
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE'
export const RESET_POSTS = 'RESET_POSTS'

//Create new post
export const CREATE_POST = 'CREATE_POST'
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS'
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE'
export const RESET_NEW_POST = 'RESET_NEW_POST'

//Validate post fields like Title, Categries on the server
export const VALIDATE_POST_FIELDS = 'VALIDATE_POST_FIELDS'
export const VALIDATE_POST_FIELDS_SUCCESS = 'VALIDATE_POST_FIELDS_SUCCESS'
export const VALIDATE_POST_FIELDS_FAILURE = 'VALIDATE_POST_FIELDS_FAILURE'
export const RESET_POST_FIELDS = 'RESET_POST_FIELDS'

//Fetch post
export const FETCH_POST = 'FETCH_POST'
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS'
export const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE'
export const RESET_ACTIVE_POST = 'RESET_ACTIVE_POST'

//Delete post
export const DELETE_POST = 'DELETE_POST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE'
export const RESET_DELETED_POST = 'RESET_DELETED_POST'

const ROOT_URL2 = window.location.href.indexOf('localhost') > 0 ? 'https://www.projectil.io/api' : '/api'

let ROOT_URL

if (window.location.href.indexOf('localhost') > 0) {
  ROOT_URL = ROOT_URL2;
} else {
  ROOT_URL = window.location.origin + ROOT_URL2;
}

// only for development - localhost
//ROOT_URL = 'http://localhost:5000/api'

/**
 * Action creator fetch posts
 *
 * @param {string} email
 * @param {object} tokenFromStorage
 *
 * @return {Object}
 */
export function fetchPosts() {
  const request = axios({
    method: 'get',
    url: `${ROOT_URL}/posts`,
    headers: []
  })

  return {
    type: FETCH_POSTS,
    payload: request
  }
}

/**
 * Action creator fetch posts success
 *
 * @param {object} posts
 *
 * @return {Object}
 */
export function fetchPostsSuccess(posts) {
  return {
    type: FETCH_POSTS_SUCCESS,
    payload: posts
  }
}

/**
 * Action creator fetch posts error
 *
 * @param {object} error
 *
 * @return {Object}
 */
export function fetchPostsFailure(error) {
  return {
    type: FETCH_POSTS_FAILURE,
    payload: error
  }
}

/**
 * Action creator validate post fields
 *
 * @param {object} props
 *
 * @return {Object}
 */
export function validatePostFields(props) {
  //note: we cant have /posts/validateFields because it'll match /posts/:id path!
  const request = axios.post(`${ROOT_URL}/posts/validate/fields`, props);

  return {
    type: VALIDATE_POST_FIELDS,
    payload: request
  }
}

/**
 * Action creator validate post fields success
 *
 * @return {Object}
 */
export function validatePostFieldsSuccess() {
  return {
    type: VALIDATE_POST_FIELDS_SUCCESS
  }
}

/**
 * Action creator validate post fields error
 *
 * @param {object} error
 *
 * @return {Object}
 */
export function validatePostFieldsFailure(error) {
  return {
    type: VALIDATE_POST_FIELDS_FAILURE,
    payload: error
  }
}

/**
 * Action creator to reset post fields
 *
 * @return {Object}
 */
export function resetPostFields() {
  return {
    type: RESET_POST_FIELDS
  }
}

/**
 * Action creator to create post
 *
 * @param {object} props
 * @param {object} tokenFromStorage
 *
 * @return {Object}
 */
export function createPost(props, tokenFromStorage) {
  const request = axios({
    method: 'post',
    data: props,
    url: `${ROOT_URL}/posts`,
    headers: {
      'Authorization': `Bearer ${tokenFromStorage}`
    }
  })

  return {
    type: CREATE_POST,
    payload: request
  }
}

/**
 * Action creator to create post success
 * @param {object} newPost
 * @return {Object}
 */
export function createPostSuccess(newPost) {
  return {
    type: CREATE_POST_SUCCESS,
    payload: newPost
  }
}

/**
 * Action creator to create post error
 * @param {object} error
 * @return {Object}
 */
export function createPostFailure(error) {
  return {
    type: CREATE_POST_FAILURE,
    payload: error
  }
}

/**
 * Action creator to reset new post
 * @return {Object}
 */
export function resetNewPost() {
  return {
    type: RESET_NEW_POST
  }
}

/**
 * Action creator to reset delete post
 * @return {Object}
 */
export function resetDeletedPost() {
  return {
    type: RESET_DELETED_POST
  }
}

/**
 * Action creator to fetch post
 * @param {numeric} id
 * @return {Object}
 */
export function fetchPost(id) {
  const request = axios.get(`${ROOT_URL}/posts/${id}`);

  return {
    type: FETCH_POST,
    payload: request
  }
}


/**
 * Action creator to fetch post success
 * @param {object} activePost
 * @return {Object}
 */
export function fetchPostSuccess(activePost) {
  return {
    type: FETCH_POST_SUCCESS,
    payload: activePost
  }
}

/**
 * Action creator to fetch post error
 * @param {object} error
 * @return {Object}
 */
export function fetchPostFailure(error) {
  return {
    type: FETCH_POST_FAILURE,
    payload: error
  }
}

/**
 * Action creator to reset active post
 * @param {numeric} id
 * @param {object} tokenFromStorage
 * @return {Object}
 */
export function resetActivePost() {
  return {
    type: RESET_ACTIVE_POST
  }
}


/**
 * Action creator to delete post
 * @param {numeric} id
 * @param {object} tokenFromStorage
 * @return {Object}
 */
export function deletePost(id, tokenFromStorage) {
  const request = axios({
    method: 'delete',
    url: `${ROOT_URL}/posts/${id}`,
    headers: {
      'Authorization': `Bearer ${tokenFromStorage}`
    }
  })
  return {
    type: DELETE_POST,
    payload: request
  }
}

/**
 * Action creator to delete post success
 * @param {object} deletedPost
 * @return {Object}
 */
export function deletePostSuccess(deletedPost) {
  return {
    type: DELETE_POST_SUCCESS,
    payload: deletedPost
  }
}

/**
 * Action creator to delete post failure
 * @param {object} response
 * @return {Object}
 */
export function deletePostFailure(response) {
  return {
    type: DELETE_POST_FAILURE,
    payload: response
  }
}
