import React, { Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CssBaseline from '@material-ui/core/CssBaseline'

import AppNavigation from '../containers/AppNavigationContainer'

import linkRenderer from '../common/components/link-renderer';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
    },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  sectionJustify: {
    padding: '.5em .5em',
    margin: '10px auto',
    maxWidth: '640px',
    textAlign: 'left',
  },
  appSpacer: theme.mixins.toolbar,
})

/**
* Public page 2 root component
*/
class PublicPage2 extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }

  /**
  * Renders PublicPage component
  *
  * @returns {ReactElement}
  */
  render() {
    const {
      classes,
      title,
      source,
    } = this.props

    return (
      <Fragment>
        <CssBaseline />
        <AppNavigation className={classes.appBar} />
        <main className={classes.content}>
          <div className={classes.layout}>
            <div className={classes.sectionJustify} >
              <Typography variant="h3" gutterBottom>
                {title}
              </Typography>
              <Divider />
              <Markdown
                source={source}
                renderers={{
                  Link: linkRenderer,
                }}
              />
            </div>
            <div className={classes.appSpacer} />
          </div>
        </main>
      </Fragment>
    )
  }
}

export default withStyles(styles)(PublicPage2)
