import {
  SET_OPEN_DRAWER,
  SET_SIGNED_OUT,
  RESET_DASHBOARD,
} from '../actions/dashboard_action'

const INITIAL_STATE = {
  open: false,
  signedOut: false,
}

/**
 * reducer for dashboard
 * @param {object} state
 * @param {object} action
 * @returns {object}
*/
export default function(state = INITIAL_STATE, action) {

  switch(action.type) {

    case SET_OPEN_DRAWER: {
      return {
        ...state,
        open: action.payload
      }
    }

    case SET_SIGNED_OUT: {
      return {
        ...state,
        signedOut: action.payload
      }
    }

    case RESET_DASHBOARD: {
      return {
        ...state,
        open: false,
        signedOut: false,
      }
    }

    default:
      return state
  }
}
