import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import globalStyles from '../assets/style/globalStyles'

import AppNavigation from '../containers/AppNavigationContainer'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
}

/**
* Register pages root component
*/
class Register extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  /**
  * Renders Register component
  *
  * @returns {ReactElement}
  */
  render() {

    return (
      <div>
        <AppNavigation />
        <div style={globalStyles.publicPageContainer}>
          <div className="section justify" >
            <Typography variant="h3" gutterBottom>
              {'Register'}
            </Typography>
            <Divider />
          </div>
          <div className="section justify" >
            {'Register ...'}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Register)
