import React, { Component } from 'react'

import AppNavigation from '../containers/AppNavigationContainer'
import SignInFormContainer from '../containers/SignInFormContainer'

/**
* SigValidate email pages root component
*/
class SignIn extends Component {

  /**
  * Renders SigIn component
  *
  * @returns {ReactElement}
  */
  render() {

    return (
      <div>
        <AppNavigation />
        <SignInFormContainer
          from={this.props.location.state.from}
        />
      </div>
    )
  }
}

export default SignIn
