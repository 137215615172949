import React, { Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CssBaseline from '@material-ui/core/CssBaseline'

import AppNavigation from '../containers/AppNavigationContainer'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
    },
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  sectionJustify: {
    padding: '.5em .5em',
    margin: '10px auto',
    maxWidth: '640px',
    textAlign: 'left',
    wordWrap: 'break-word',
  },
  appSpacer: theme.mixins.toolbar,
})

/**
* Public page root component
*/
class PublicPage extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  /**
  * Renders PublicPage component
  *
  * @returns {ReactElement}
  */
  render() {
    const {
      classes,
    } = this.props

    return (
      <Fragment>
        <CssBaseline />
        <AppNavigation className={classes.appBar} />
        <main className={classes.content}>
          <div className={classes.layout}>
            <div className={classes.sectionJustify} >
              <Typography variant="h3" gutterBottom>
                {'Projectil.io'}
              </Typography>
              <Divider />
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'What is Projectil.io?'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Projectil.io is project management software. It\'s a tool to help you plan, organize, and manage your team\'s work, from start to finish. Good project management software also acts as a collaboration tool. You can coordinate team tasks so everyone knows who\'s doing what. Share feedback, files, and status updates. Plus, get a complete view of work so teams tackle the right tasks at the right time.'}
              </Typography>
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'Why Projectil.io ?'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Share your plans with clients, project by project. Clients will know what’s next, you’ll stay on top of the communication.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Help your team do its best work.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Get the features you need to plan and manage projects.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Take your work to the advance level.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Plan better projects, track what’s important to your team, and so you can produce better work, faster.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Increase your team\'s efficiency.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Plan better projects. Use Timeline to create project plans with start dates and dependencies so you can stay on schedule and hit your deadlines—even as work changes.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Customize your processes. Create custom templates for work your team does often and track the data that’s most important to you with custom fields, so projects are successful every time.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Report on Progress. Run reports on the criteria you care about to see how work is progressing and where to focus your effort.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Control your work. Create private and comment-only projects for sensitive work, so it\'s seen by the right people and your data stays intact.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Get an overview of your team’s plans.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'React to changes fast and effortlessly.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Understand the progress of your projects.'}
              </Typography>
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'What makes Projectil.io different?'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'...'}
              </Typography>
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'Who needs Projectil.io?'}
              </Typography>
              <Typography variant="h5" gutterBottom>
                {'Managers'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Managers can use project management software to outline strategies, plan projects, to help you organize, and manage your team\'s work, lead efficient team meetings and much more.'}
              </Typography>
              <Typography variant="h5" gutterBottom>
                {'Advisors'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Consultants and advisors can use project management software as an effective client communication and presentation tool for requirements.'}
              </Typography>
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'Why do you need it?'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'Managing projects is hard work. Even harder if you don\'t use software or have work spread across too many tools. It\'s easy to lose track of all the moving pieces—and tough to stay up to date. That’s when things fall through the cracks. Use one tool to organize projects, make communication easy, and meet deadlines.'}
              </Typography>
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'Top Projectil.io uses.'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'...'}
              </Typography>
            </div>
            <div className={classes.sectionJustify} >  
              <Typography variant="h4" gutterBottom>
                {'How starting use Projectil.io?'}
              </Typography>
              <Typography variant="subtitle1" component="p"
                gutterBottom
              >
                {'...'}
              </Typography>
            </div>
            <div className={classes.appSpacer} />
          </div>
        </main>
      </Fragment>
    )
  }
}

export default withStyles(styles)(PublicPage)
