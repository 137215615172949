export default `
Last modified: October 7th, 2018.

### 1. Services

- 1.1 These Material-UI Terms of Service (these “Terms”) apply to the features and functions provided by Functional Software, Inc. (“Material-UI,” “our,” or “we”) via material-ui.com (the “Site”) (collectively, the “Services”). By accessing or using the Site or the Services, you agree to be bound by these Terms. If you do not agree to these Terms, you are not allowed to use the Site or the Services. The “Effective Date” of these Terms is the date you first use the Site, or access any of the Services.

Project management is the process of planning, managing and executing the required work in a specified time to achieve the organizational goals. There are certain guidelines that one would need for the successful completion and execution of a project. Working according to the deadlines, managing resources, and organizing tasks at every stage of the process are some of the important issues to be dealt with while managing a project.

Let’s look for the 7 basic and most important rules of the project management.

1. Managing Time is Pertinent
Time management is one of the most important aspects of Project Management, which includes understanding time requirements and restrictions and scheduling the tasks accordingly.

The Project Manager needs to analyze the time for all the tasks prior to the initiation of the project, set certain deadlines for every task, and ensure their completion too.

2. Keeping Buffer Time is Useful
It often happens that one or the other module doesn’t always take the presumed time duration for its completion. Every stage requires some buffer time to get started or completed. Moreover, procrastination always comes crawling in at one point or another. So, taking additional buffer time into consideration is an important aspect of the project management process.

3. Dividing Tasks Into Smaller Parts
For better planning and execution, it is a great approach to break down a large project into smaller time-boxes. Each time-box must be scheduled with start and end dates to make sure that the deadlines are religiously followed.

4. Implementation of Frequent Review Cycles
The review process helps in effective management of the project. The feedback is drawn by reviewing the modules and their functionality at different levels to help in rectifying mistakes and improving quality. This should be done more frequently and at shorter intervals to speed up the flow of the project and making it error-free at the same time.

5. Prioritizing of The Tasks
Project Management is best done by prioritizing important tasks and focusing on them throughout the project cycle. So, all you need to do is set and manage a list of the priority tasks which is in line with your business goals and meet the demands of the project. This list should be followed vigorously for effective project management.

6. Time Compression to Increase Effectiveness
This is another way of managing time for better management of a project. It is the job of the Project Manager to ensure that the project team is not taking more than the required time for the completion of tasks. For compressing the time in a project, time should be saved to its best at every stage to stay ahead of deadlines and get more time for the review cycles.

7. Avoiding Task Duplication Is Beneficial
A project may have the same tasks repeatedly used in different modules at various stages. This is one of the things that is needed to be avoided by the project manager. Task duplication could be eliminated by introducing templates in the modules, wherever required.

These 7 mantras of project management are all you need for the successful completion and impeccable execution of your project. Understanding of these basics and standing by them to achieve quality project standards is always beneficial for overall effective execution of a Project.
`;
