/*eslint-disable react/no-multi-comp*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import globalStyles from '../assets/style/globalStyles'

import AppNavigation from '../containers/AppNavigationContainer'

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  icon12: {
    fontSize: 12,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

/**
* Register snack bar pages component
* @param {object} variant
* @param {object} Transition
* @returns {ReactElement}
 */
class SnackBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  /**
   * Function Create notifier message
   * @param {string} message
   * @returns {ReactElement}
   */
  handleClickNotiSnack = message => () => {
    const {
      classes,
      enqueueSnackbar
    } = this.props;
    enqueueSnackbar(message, {
      action: (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          size="small"
        >
          <CloseIcon className={classes.icon2} />
        </IconButton>
      ),
    });
  };

  handleClickVariant = variant => () => {
    const { classes } = this.props;
    // variant could be success, error, warning or info
    this.props.enqueueSnackbar(`This is a ${variant} message!`, {
      variant,
      action: (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          size="small"
        >
          <CloseIcon className={classes.icon2} />
        </IconButton>
      ),
    });
  };

  handleClickPersistVariant = variant => () => {
    // variant could be success, error, warning or info
    //this.props.enqueueSnackbar('This is a warning message!', { variant, persist: true });
    this.props.enqueueSnackbar(`This is a persist ${variant} message!`, {
      variant,
      //autoHideDuration: 3000,
      persist: true,
      action: (
        <Button size="small">{'Dismiss'}</Button>
      ),
    });
  };

  /**
  * Renders Register component
  *
  * @returns {ReactElement}
  */
  render() {
    //const { classes } = this.props;

    return (
      <div>
        <AppNavigation />
        <div style={globalStyles.publicPageContainer}>
          <div className="section justify" >
            <Typography variant="h3" gutterBottom>
              {'SnackBar'}
            </Typography>
            <Divider />
            <Button onClick={this.handleClickNotiSnack('I love snacks.')}>{'Show default'}</Button>
            <Button onClick={this.handleClickVariant('success')}>{'Show success snackbar'}</Button>
            <Button onClick={this.handleClickVariant('error')}>{'Show error snackbar'}</Button>
            <Button onClick={this.handleClickVariant('warning')}>{'Show warning snackbar'}</Button>
            <Button onClick={this.handleClickVariant('info')}>{'Show info snackbar'}</Button>
            <Button onClick={this.handleClickPersistVariant('success')}>{'Show persistent success snackbar'}</Button>
            <Button onClick={this.handleClickPersistVariant('error')}>{'Show persistent error snackbar'}</Button>
            <Button onClick={this.handleClickPersistVariant('warning')}>{'Show persistent warning snackbar'}</Button>
            <Button onClick={this.handleClickPersistVariant('info')}>{'Show persistent info snackbar'}</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(SnackBar));
