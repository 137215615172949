import { connect } from 'react-redux'

import {
  setAnchorElApp,
  resetAnchorElApp,
} from '../actions/app_navigation_action'

import AppNavigation from '../components/AppNavigation.js'

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  return {
    anchorElApp: state.app_navigation.anchorElApp,
    isAuthenticated: state.user.isAuthenticated,
  };
}
/* eslint-disable no-unused-vars */
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setAnchorElApp: (value) => {
      dispatch(setAnchorElApp(value));
    },
    resetAnchorElApp: () => {
      dispatch(resetAnchorElApp());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation)
