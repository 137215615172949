export default `
###### Policy effective as of 20 February 2019

When using our services, customers are entrusting their data, including information about other individuals (i.e., personal data), into our care and it is one of our core duties to each Workspace Owner to ensure that appropriate measures are in place on our side for the protection of the personal data that are processed via his Workspace (“Relevant Data”). This Data Protection Policy (“Policy”) describes some of those measures. All capitalised terms used in this Policy and not otherwise defined herein have the meanings ascribed to them in our Terms of Service.

#### Confidentiality

1. We place strict controls over our employees’ access to Relevant Data and are committed to ensuring that these data are not seen by anyone who should not have access to them.

2. The operation of the Service requires that some of our employees have access to the systems we use for processing Relevant Data (e.g., in order to diagnose a problem you are having with the Service, we may need to access your Workspace, including its Relevant Data). These employees are prohibited from using their access permissions to view Relevant Data unless it is necessary to do so.

3. We have technical controls and policies in place to ensure that any access to a Workspace is logged. All of our employees and contract personnel are bound to our policies regarding Relevant Data and the security of these data is a matter of great importance to us.

#### Data encryption

4. We transmit data over public networks using proper encryption. This includes data transmitted between our customers and the Service. Our systems support some of the most advanced commercially reasonable cipher suites to encrypt all data in transit.

5. We monitor the changing cryptographic landscape and upgrade our cipher suite choices as the landscape changes.

#### Service infrastructure

7. The Service is hosted in data centres operated by industry-leading service providers who offer state-of-the-art physical and other protection for the cloud infrastructure underlying our Service user environment. These cloud providers are responsible for restricting access to the above infrastructure to authorised personnel only.

8. Each customer’s data are hosted in the public cloud resources allocated to us and segregated logically by the Service application. We use a combination of storage technologies to ensure that Relevant Data are protected from hardware failures and return quickly when requested.

#### Availability

9. We understand that you rely on the Service to work, and are committed to making it highly-available so that you could count on it. The Service runs on systems that are “fault tolerant”, for failures of individual servers as well as entire data centres. Our operations team tests disaster-recovery measures regularly and staffs an around-the-clock on-call team to quickly resolve unexpected incidents.

10. Workspace Data (including Relevant Data) are stored redundantly at multiple locations in our cloud provider’s data centres to ensure availability. We have adequate backup and restoration procedures in place to allow recovery from a major disaster. Workspace Data and our source code are regularly backed up and our operations team is alerted in case of a failure with this system.

#### Security practices in product development

11. New features, functionality, and design changes go through a security review process. In addition, our code is audited, tested, and manually peer-reviewed prior to being deployed to production.

#### Access to the service

12. The Service, howsoever accessed, requires all users to authenticate, and users are granted unique identifiers for that purpose.

#### Changes to this policy

We may revise this Policy from time to time to reflect changes to the Service, applicable laws, regulations or standards or other changes that may occur in our business. We shall post the revised Policy (or, as the case may be, our new data protection policy) on the same webpage where we published this Policy or on such other webpage as we then may habitually use for publishing materials such as the Policy. We may also use the Service, email or other means for notifying customers of such policy changes. The revised Policy (or, as applicable, the new one) will be effective when posted as described unless the document itself specifies a later time for its entry into force.

`;
