import React, { Component } from 'react'

/**
 * NoMatch component
 */
export default class NoMatch extends Component {

  /**
   * Renders Example1
   *
   * @returns {ReactElement}
   */
  render() {
    return (
      <div>
        <h2>{'Sorry no content available at '}{this.props.location.pathname}{' !'}</h2>
      </div>
    )
  }
}
