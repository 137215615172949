import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'

import { PATHS } from '../common/constants'

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
})

/**
 * Dashboard component
 * @param {event} event
*/
class AppNavigation extends Component {

  static contextTypes = {
    router: PropTypes.object,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    anchorElApp: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    resetAnchorElApp: PropTypes.func.isRequired,
    setAnchorElApp: PropTypes.func.isRequired,
  }

  /**
   * @constructor
   */
  constructor() {
    super(...arguments);

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClickMenuItem = this.handleClickMenuItem.bind(this)
  }

  /**
   * Reset data
   */
  componentWillUnmount() {
    this.props.resetAnchorElApp()
  }

  handleClick = event => {
    this.props.setAnchorElApp(event.currentTarget)
  }

  handleClose = () => {
    this.props.setAnchorElApp(null)
  }

  /**
   * Dashboard component
   * @param {string} path
  */
  handleClickMenuItem(path) {
    this.props.setAnchorElApp(null)
    this.context.router.history.push(path)
  }

  /**
   * Renders component
   * @returns {ReactElement}
   */
  render() {
    const {
      classes,
      anchorElApp,
      isAuthenticated,
    } = this.props

    return (

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="sticky"
          className={classes.appBar}
        >
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              aria-owns={anchorElApp ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorElApp}
              open={Boolean(anchorElApp)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={() => this.handleClickMenuItem(PATHS.HOME)} >{'Home'}</MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.FEATURES)}
              >
                {'Features'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.PRICING)}
              >
                {'Pricing'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.TERMS)}
              >
                {'Terms of service'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.POLICY)}
              >
                {'Privacy policy'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.DATA_POLICY)}
              >
                {'Data protection policy'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.CONTACT)}
              >
                {'Get support & Contact'}
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.DASHBOARD)}
              >
                {isAuthenticated ? 'Dashboard' : 'Sign in'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.REGISTER)}
              >
                {'Register'}
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem
                onClick={() => this.handleClickMenuItem('/checkout')}
              >
                {'Checkout'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.SNACKBAR)}
              >
                {'SnackBar'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.TIMELINE)}
              >
                {'Timeline'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.PRICING2)}
              >
                {'Pricing 2'}
              </MenuItem>
              <MenuItem
                onClick={() => this.handleClickMenuItem(PATHS.MD)}
              >
                {'MarkDown'}
              </MenuItem>

            </Menu>
            <Typography variant="h6"
              color="inherit"
              className={classes.grow}
            >
              {'Projectil.io'}
            </Typography>
            {!isAuthenticated &&
            <Button
              color="inherit"
              onClick={() => this.handleClickMenuItem(PATHS.REGISTER)}
            >
              {'Register'}
            </Button>
            }
            <Button
              color="inherit"
              onClick={() => this.handleClickMenuItem(PATHS.DASHBOARD)}
            >
              {isAuthenticated ? 'Dashboard' : 'Sign in'}
            </Button>
          </Toolbar>
          {this.props.children}
        </AppBar>
      </div>
    )
  }
}

export default withStyles(styles)(AppNavigation)
