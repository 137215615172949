import axios from 'axios'

//Get current user(me) from token in localStorage
export const ME_FROM_TOKEN = 'ME_FROM_TOKEN'
export const ME_FROM_TOKEN_SUCCESS = 'ME_FROM_TOKEN_SUCCESS'
export const ME_FROM_TOKEN_FAILURE = 'ME_FROM_TOKEN_FAILURE'
export const RESET_TOKEN = 'RESET_TOKEN'

//Sign Up User
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'
export const RESET_USER = 'RESET_USER'

//Sign In User
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE'


//validate email, if success, then load user and login
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE'

//called when email is updated in profile to update main user's email state
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'

//log out user
export const LOGOUT_USER = 'LOGOUT_USER'

//LogIn user - set show password flag
export const SET_SHOW_PASSWORD = 'SET_SHOW_PASSWORD'

const ROOT_URL2 = window.location.href.indexOf('localhost') > 0 ? 'https://www.projectil.io/api' : '/api'

let ROOT_URL

if (window.location.href.indexOf('localhost') > 0) {
  ROOT_URL = ROOT_URL2
} else {
  ROOT_URL = window.location.origin + ROOT_URL2
}

// only for development - localhost
//ROOT_URL = 'http://localhost:5000/api'

// only for development - test from android mobile - wifi inner network
//ROOT_URL = 'http://192.168.0.103:5000/api'

/**
 * Action creator to validate email
 *
 * @param {object} validateEmailToken
 *
 * @return {Object}
 */
export function validateEmail(validateEmailToken) {
  //check if token from welcome email is valid,
  //if so, update email as verified and login the user from response
  const request = axios.get(`${ROOT_URL}/validateEmail/${validateEmailToken}`)

  return {
    type: VALIDATE_EMAIL,
    payload: request
  }
}

/**
 * Action creator to validate email success
 *
 * @param {object} currentUser
 *
 * @return {Object}
 */
export function validateEmailSuccess(currentUser) {
  return {
    type: VALIDATE_EMAIL_SUCCESS,
    payload: currentUser
  }
}

/**
 * Action creator to validate email error
 *
 * @param {object} error
 *
 * @return {Object}
 */
export function validateEmailFailure(error) {
  return {
    type: VALIDATE_EMAIL_FAILURE,
    payload: error
  }
}

/**
 * Action creator to me from token
 *
 * @param {object} tokenFromStorage
 *
 * @return {Object}
 */
export function meFromToken(tokenFromStorage) {
  //check if the token is still valid, if so, get me from the server

  const request = axios({
    method: 'get',
    url: `${ROOT_URL}/me/from/token?token=${tokenFromStorage}`,
    headers: {
      'Authorization': `Bearer ${tokenFromStorage}`
    }
  })

  return {
    type: ME_FROM_TOKEN,
    payload: request
  }
}

/**
 * Action creator to me from token success
 *
 * @param {object} currentUser
 *
 * @return {Object}
 */
export function meFromTokenSuccess(currentUser) {
  return {
    type: ME_FROM_TOKEN_SUCCESS,
    payload: currentUser
  }
}

/**
 * Action creator to me from token error
 *
 * @param {object} error
 *
 * @return {Object}
 */
export function meFromTokenFailure(error) {
  return {
    type: ME_FROM_TOKEN_FAILURE,
    payload: error
  }
}


/**
 * Action creator to reset token
 *
 * @return {Object}
 */
export function resetToken() {//used for logout
  return {
    type: RESET_TOKEN
  }
}


/**
 * Action creator to sign up user
 *
 * @param {object} formValues
 *
 * @return {Object}
 */
export function signUpUser(formValues) {
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    }
  };
  const request = axios.post(`${ROOT_URL}/users/signup`, formValues, axiosConfig);

  return {
    type: SIGNUP_USER,
    payload: request
  }
}

/**
 * Action creator to sign up user success
 *
 * @param {object} user
 *
 * @return {Object}
 */
export function signUpUserSuccess(user) {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: user
  }
}

/**
 * Action creator to sign up user error
 *
 * @param {object} error
 *
 * @return {Object}
 */
export function signUpUserFailure(error) {
  return {
    type: SIGNUP_USER_FAILURE,
    payload: error
  }
}


/**
 * Action creator to reset user
 *
 * @return {Object}
 */
export function resetUser() {
  return {
    type: RESET_USER,
  }
}

/**
 * Action creator to sign in user
 *
 * @param {object} formValues
 *
 * @return {Object}
 */
export function signInUser(formValues) {
  const request = axios.post(`${ROOT_URL}/users/signin`, formValues);

  return {
    type: SIGNIN_USER,
    payload: request
  }
}

/**
 * Action creator to sign in user success
 *
 * @param {object} user
 *
 * @return {Object}
 */
export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  }
}

/**
 * Action creator to sign in user error
 *
 * @param {object} error
 *
 * @return {Object}
 */
export function signInUserFailure(error) {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: error
  }
}

/**
 * Action creator to logout user
 *
 * @return {Object}
 */
export function logoutUser() {
  return {
    type: LOGOUT_USER
  }
}

/**
 * Action creator to update user email
 *
 * @param {object} email
 *
 * @return {Object}
 */
export function updateUserEmail(email) {
  return {
    type: UPDATE_USER_EMAIL,
    payload:email
  }
}

/**
 * Action creator to set flag show password
 *
 * @param {object} showPassword
 * @return {Object}
 */
export function setShowPassword(showPassword) {
  return {
    type: SET_SHOW_PASSWORD,
    payload: showPassword
  }
}
