export const PATHS = {
  HOME: '/',
  FEATURES: '/features',
  PRICING: '/pricing',
  TERMS: '/terms',
  POLICY: '/policy',
  DATA_POLICY: '/data_policy',
  CONTACT: '/contact',
  DASHBOARD: '/dashboard',
  REGISTER: '/register',
  // internal paths
  CHECKOUT: '/checkout',
  SIGNIN: '/signin',
  SNACKBAR: '/snackbar',
  TIMELINE: '/timeline',
  PRICING2: '/pricing2',
  MD: '/md',
}
