export default `
#### Can I use Projectil.io for free?

Yes! You can use **Projectil.io** for free for as long as you like, to create advanced plans and projects, in both commercial and non-commercial projects. All free components are available to you.
We also offer a free 14-day trial of our **Projectil.io Pro** or **Projectil.io Enterprise** service, that adds additional features for professionals and large teams.

#### What if it doesn't work as expected?

If you're not fully satisfied with **Projectil.io** for any reason, no worries. We offer a full 30-day money back guarantee, no questions asked.
Use the support option in the app, or simply write in to **support@projectil.io** and we'll take care of you.

#### I need a bigger plan!

Additional users can be added to the team plan when subscribing or at any later time.
If you have a large organization, or need additional support, please contact us to make sure we can serve you in the best way possible.

#### Am I in a contract when I take a free trial or subscribe?

No. You are under no obligation when you sign up for a monthly subscription. You can cancel at any time.

#### What payment options do you accept?

We currently accept all major credit cards, including Visa, MasterCard.
We unfortunately do not accept PayPal at this time.

#### Further questions?

If you have any questions, please contact **Projectil.io** support - **support@projectil.io**.

#### Contact information

**Owner and Data Controller**: Bohdan Vetrák

**Address**: Starohorská 1043/2A, 664 12 Oslavany, Czech republic

**Owner contact email**: <mailto:admin@projectil.io>

**Owner contact email**: <admin@projectil.io>

[links](https://en.wikipedia.org/wiki/Hyperlink)

#### Tables?

| Feature   | Support |
| --------- | ------- |
| tables    | ✔ |
| alignment | ✔ |
| wewt      | ✔ |

`;
