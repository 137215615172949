import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { SnackbarProvider } from 'notistack'

import withWidth from '@material-ui/core/withWidth';

import {
  meFromToken,
  meFromTokenSuccess,
  meFromTokenFailure,
  resetToken
} from './actions/users'

// import components
import Dashboard from './pages/Dashboard'
import HomeView from './pages/Home'
import Checkout from './pages/Checkout'
import NoMatch from './pages/NoMatch'
import Pricing from './pages/Pricing'
//import PublicPage from './pages/PublicPage'
import Register from './pages/Register'
import SignIn from './pages/SignIn'
import ValidateEmail from './pages/ValidateEmail'
import SnackBar from './pages/SnackBar'
import Timeline from './pages/Timeline'
import Pricing2 from './pages/Pricing2'
import PublicPage2 from './pages/PublicPage2'

// import documents as constants
import sampleLinks from './common/md/links';
import featuresMD from './common/md/features';
import contactMD from './common/md/contact';
import termsOfServiceMD from './common/md/termsOfService';
import privacyPolicyMD from './common/md/privacyPolicy';
import dataProtectionPolicyMD from './common/md/dataProtectionPolicy';

// import documents
/*
import features from './assets/md/Features.md'
import contact from './assets/md/Contact.md'
import termsOfService from './assets/md/TermsOfService.md'
import privacyPolicy from './assets/md/PrivacyPolicy.md'
import dataProtectionPolicy from './assets/md/DataProtectionPolicy.md'
*/

import { PATHS } from './common/constants'
//import routes from './router-config'

/**
 * Application component
*/
class App extends Component {

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    loadUserFromToken: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired,
  }

  /**
   * Load User from token
   */
  componentWillMount() {
    this.props.loadUserFromToken();
  }

  /**
   * Renders component
   * @returns {ReactElement}
   */
  render() {
    const { width } = this.props;
    /*
    routes.map((route,index) => (
        'key',
        index,
        'path',
        route.path,
        'exact',
        route.exact)
    ))
    */
    //      <AppContainer />
    //          <Route path={'/dashboard'} component={() => (this.props.isAuthenticated ? (<Dashboard />) : (<Redirect to={{pathname: '/signin', state: { from: '/dashboard'}}}/>))} />

    //              <Route path={PATHS.CONTACT} component={() => (<PublicPage title={'Get support & Contact'} mdFile={contact} />)} />

    const myVertical = ((width === 'xs')
      || (width === 'sm'))
      ? 'bottom'
      : 'top'

    return (
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: myVertical,
            horizontal: 'center',
          }}
        >
          <div>
            {this.props.children}
            <Switch>
              <Route path={PATHS.HOME}
                component={HomeView}
                exact={true}
              />
              <Route path={PATHS.FEATURES} component={() => (<PublicPage2 title={'Features'} source={featuresMD} />)} />
              <Route path={PATHS.PRICING} component={Pricing} />
              <Route path={PATHS.TERMS} component={() => (<PublicPage2 title={'Terms of service'} source={termsOfServiceMD} />)} />
              <Route path={PATHS.POLICY} component={() => (<PublicPage2 title={'Privacy policy'} source={privacyPolicyMD} />)} />
              <Route path={PATHS.DATA_POLICY} component={() => (<PublicPage2 title={'Data protection policy'} source={dataProtectionPolicyMD} />)} />
              <Route path={PATHS.CONTACT} component={() => (<PublicPage2 title={'Get support & Contact'} source={contactMD} />)} />

              <Route path={PATHS.SIGNIN} component={SignIn} />
              <Route path={PATHS.REGISTER} component={Register} />
              <Route path={PATHS.CHECKOUT} component={Checkout} />
              <Route path={PATHS.DASHBOARD} component={() => (this.props.isAuthenticated ? (<Dashboard />) : (<Redirect to={{pathname: PATHS.SIGNIN, state: { from: PATHS.DASHBOARD}}} />))} />
              <Route path={'/validateEmail/:token'} component={ValidateEmail} />
              <Route path={PATHS.SNACKBAR} component={SnackBar} />
              <Route path={PATHS.TIMELINE} component={Timeline} />
              <Route path={PATHS.PRICING2} component={Pricing2} />
              <Route path={PATHS.MD} component={() => (<PublicPage2 title={'Get support & Contact'} source={sampleLinks} />)} />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </SnackbarProvider>
      </BrowserRouter>
    )
  }
}

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {

  return {

    loadUserFromToken: () => {
      const token = sessionStorage.getItem('jwtToken')
      if(!token || token === '') {//if there is no token, dont bother
        return
      }
      //fetch user from token (if server deems it's valid token)
      dispatch(meFromToken(token))
        .then((response) => {
          if (!response.error) {
            //reset token (possibly new token that was regenerated by the server)
            sessionStorage.setItem('jwtToken', response.payload.data.token)
            dispatch(meFromTokenSuccess(response.payload))
          } else {
            sessionStorage.removeItem('jwtToken');//remove token from storage
            dispatch(meFromTokenFailure(response.payload))
          }
        })
    },

    resetMe: () => {
      sessionStorage.removeItem('jwtToken') //remove token from storage
      dispatch(resetToken())
    },

  }
}

export default withWidth()(connect(mapStateToProps, mapDispatchToProps)(App))
