import axios from 'axios'

//Resend validation email
export const RESEND_VALIDATION_EMAIL = 'RESEND_VALIDATION_EMAIL'
export const RESEND_VALIDATION_EMAIL_SUCCESS = 'RESEND_VALIDATION_EMAIL_SUCCESS'
export const RESEND_VALIDATION_EMAIL_FAILURE = 'RESEND_VALIDATION_EMAIL_FAILURE'
export const RESET_RESEND_EMAIL_STATE = 'RESET_RESEND_EMAIL_STATE';


const ROOT_URL2 = window.location.href.indexOf('localhost') > 0 ? 'https://www.projectil.io/api' : '/api'

let ROOT_URL

if (window.location.href.indexOf('localhost') > 0) {
  ROOT_URL = ROOT_URL2;
} else {
  ROOT_URL = window.location.origin + ROOT_URL2;
}

// only for development - localhost
//ROOT_URL = 'http://localhost:5000/api'

/**
 * Action creator resend validation email
 * @param {object} tokenFromStorage
 *
 * @return {Object}
 */
export function resendValidationEmail(tokenFromStorage) {
  const request = axios({
    method: 'get',
    url: `${ROOT_URL}/resendValidationEmail`,
    headers: {'Authorization': `Bearer ${tokenFromStorage}`}
  })

  return {
    type: RESEND_VALIDATION_EMAIL,
    payload: request
  }
}

/**
 * Action creator resend validation email success
 * @param {object} message
 *
 * @return {Object}
 */
export function resendValidationEmailSuccess(message) {
  return {
    type: RESEND_VALIDATION_EMAIL_SUCCESS,
    payload: message
  }
}

/**
 * Action creator resend validation email error
 * @param {object} error
 *
 * @return {Object}
 */
export function resendValidationEmailFailure(error) {
  return {
    type: RESEND_VALIDATION_EMAIL_FAILURE,
    payload: error
  }
}

/**
 * Action creator to reset state resend validation email
 *
 * @return {Object}
 */
export function resetResendEmailState() {
  return {
    type: RESET_RESEND_EMAIL_STATE
  }
}
