import React, { Component } from 'react';

//import HeaderContainer from '../containers/HeaderContainer';
//import ValidateEmailAlertContainer from '../containers/ValidateEmailAlertContainer';

/**
* Validate email pages root component
*/
class ValidateEmail extends Component {

  /**
  * Renders ValidateEmail component
  *
  * @returns {ReactElement}
  */
  render() {
    return (
      <div>
        {'Validate email ...'}
      </div>
    );
  }
}

export default ValidateEmail;

/*
      <div>
        <HeaderContainer type="posts_new" />
        <ValidateEmailAlertContainer
          token={this.props.params.token}
          autoValidateToken={true}
        />
      </div>
*/
